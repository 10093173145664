:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._devtools-container_66gq2_7 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1b1e2e;
  border-left: 1px solid #2e3247;
}
._devtools-container_66gq2_7 ._devtools-header-container_66gq2_15 {
  min-height: 64px;
}
._devtools-container_66gq2_7 ._devtools-body-container_66gq2_18 {
  height: 100%;
  display: flex;
  overflow: hidden;
}

._disable-mouse_66gq2_24 {
  pointer-events: none;
}