@import '../../submodules/reporter/lib/variables';
/// Prism styles from Cypress Reporter

/// frontend/packages/test-replay/submodules/reporter/errors/prism.scss
/// Currently errors when importing from there due to a relative import from
/// node_modules.
///
code[class*='language-'],
pre[class*='language-'] {
  color: $gray-400;
  margin: 0;
  text-shadow: $black 0px 1px;
}

:not(pre) > code[class*='language-'],
pre[class*='language-'] {
  background: $gray-1000;
}

// .token.comment,
// .token.prolog,
// .token.doctype,
// .token.cdata {
// 	color: slategray;
// }

.token.punctuation {
  color: $gray-400;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: $red-400;
}

.token.selector,
.token.attr-name,
.token.string,
.token.char,
.token.builtin,
.token.inserted {
  color: $jade-400;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.regex,
.token.important,
.token.variable {
  color: $orange-400;
  background: $gray-1000;
}

.token.atrule,
.token.attr-value,
.token.keyword,
.token.function,
.token.class-name {
  color: $indigo-400;
}

.line-numbers .line-numbers-rows {
  border-right: 0;
}

.line-numbers-rows > span:before {
  color: $gray-800;
}

.line-highlight {
  background: linear-gradient(
    to right,
    rgba($gray-100, 0.1) 100%,
    rgba($gray-100, 0)
  );

  &:before {
    display: none;
  }
}

.test-error-code-frame pre[data-line] {
  padding-left: 0.5em;
  position: relative;
}
