:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._browser-header-container_bw2q0_7 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #2e3247;
  padding: 16px;
  height: 64px;
  gap: 12px;
}
._browser-header-container_bw2q0_7 ::selection {
  background: #e1e3ed;
  color: #434861;
}

._shadow_bw2q0_21:hover {
  box-shadow: 0 0 0 2px #5a5f7a;
  border: 1px solid #e1e3ed;
}

._text-display_bw2q0_26 {
  font-size: 14px;
  font-weight: 400;
  color: #747994;
  line-height: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._url-bar_bw2q0_36 {
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #434861;
  background-color: #2e3247;
  padding: 6px 12px;
  height: 32px !important;
  max-height: 32px !important;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  display: inline;
  min-width: 100px;
  cursor: default;
  flex: 1;
}

._aspect-ratio-container_bw2q0_56 {
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #434861;
  background-color: #2e3247;
  padding: 6px 12px;
  height: 32px !important;
  max-height: 32px !important;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  cursor: default;
}
._aspect-ratio-container_bw2q0_56 ._aspect-ratio-icon_bw2q0_72 {
  color: #afb3c7;
  margin-right: 8px;
  margin-bottom: -3px;
}

._devtools-button_bw2q0_78 {
  all: unset;
  font-size: 14px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid #434861;
  background-color: #2e3247;
  padding: 6px 12px;
  height: 32px !important;
  max-height: 32px !important;
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  min-width: 142px;
  cursor: pointer;
  color: #e1e3ed;
}
._devtools-button_bw2q0_78:active {
  border: 1px solid #6470f3;
  box-shadow: 0px 0px 0px 2px #2f3ab0;
}

._url-page-loading_bw2q0_102 {
  color: #afb3c7;
  background-color: #25283c;
}