:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._container_k8sdv_7 {
  display: flex;
  align-items: stretch;
  background-color: #1b1e2e;
  padding: 16px;
  gap: 16px;
  border-bottom: 1px solid #2e3247;
  height: 64px;
}
._container_k8sdv_7 ._tooltipContainer_k8sdv_16 {
  display: flex;
}
._container_k8sdv_7 ._tooltipContainer_k8sdv_16:hover ._iconQuestionMark_k8sdv_19 {
  opacity: 1;
}
._container_k8sdv_7 ._tooltipContainer_k8sdv_16 ._iconQuestionMark_k8sdv_19 {
  color: #e1e3ed;
  transition: 1s;
  opacity: 0.6;
}
._container_k8sdv_7 ._tooltipContainer_k8sdv_16 ._iconQuestionMark_k8sdv_19 path:first-of-type {
  fill: #5a5f7a;
}
._container_k8sdv_7 ._closeButton_k8sdv_30 {
  border-color: #2e3247 !important;
  background-color: transparent !important;
}

._cta-tooltip-overlay_k8sdv_35 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: stretch;
  gap: 6px;
  padding: 12px 16px;
}
._cta-tooltip-overlay_k8sdv_35 ._cta-title_k8sdv_43 {
  color: #2e3247;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
}
._cta-tooltip-overlay_k8sdv_35 ._cta-description_k8sdv_49 {
  color: #5a5f7a;
  line-height: 20px;
  font-size: 14px;
}
._cta-tooltip-overlay_k8sdv_35 ._keystrokes-container_k8sdv_54 {
  border-top: 1px solid #f3f4fa;
  padding-top: 12px;
  margin-top: 6px;
  width: 100%;
  display: flex;
  gap: 4px;
  justify-content: flex-end;
}
._cta-tooltip-overlay_k8sdv_35 ._keystroke_k8sdv_54 {
  border-radius: 4px;
  border: 1px solid #e1e3ed;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  color: #5a5f7a;
}