:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._container_1q4gd_7 {
  flex: 1;
  position: relative;
}

._listContainer_1q4gd_12 {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  min-height: 0;
}
._listContainer_1q4gd_12._hidden_1q4gd_19 {
  visibility: hidden;
}

._detailsContainer_1q4gd_23 {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #1b1e2e;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-height: 0;
}

._network-filters_1q4gd_36 {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 64px;
  border-bottom: 1px solid #2e3247;
  padding: 16px;
}
._network-filters_1q4gd_36 ._search-input-filter_1q4gd_44 {
  flex: 1;
}

._network-item-list_1q4gd_48 {
  flex: 1;
}

._emptyContainer_1q4gd_52 {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1b1e2e;
  align-items: center;
  width: 100%;
  justify-content: center;
}
._emptyContainer_1q4gd_52 ._emptyStateDescription_1q4gd_61 {
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #d0d2e0;
}