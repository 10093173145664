:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._virtualized-item_1gl4p_7 {
  position: relative;
}

._devtools-item-separator_1gl4p_11 {
  z-index: 1;
  position: absolute;
}
._devtools-item-separator_1gl4p_11._top_1gl4p_15 {
  top: -6px;
}
._devtools-item-separator_1gl4p_11._bottom_1gl4p_18 {
  bottom: -6px;
}

._container_1gl4p_22 {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 2px 16px;
  color: #bfc2d4;
  width: 100%;
  border-left: 1px solid transparent;
  overflow: hidden;
}
._container_1gl4p_22 ._focusBorder_1gl4p_32 {
  position: absolute;
  left: -3px;
  top: 0;
  border-radius: 4px;
  height: 100%;
  width: 6px;
  border-right: 3px solid;
}
._container_1gl4p_22 ._primaryBar_1gl4p_41 {
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 3px 0px;
  justify-content: space-between;
}
._container_1gl4p_22 ._primaryBar_1gl4p_41 ._leading_1gl4p_48 {
  display: inline-block;
  margin: 1px 0;
  flex-shrink: 0;
}
._container_1gl4p_22 ._primaryBar_1gl4p_41 ._collapsible_1gl4p_53 {
  width: 100%;
  text-align: left;
  display: block;
}
._container_1gl4p_22 ._primaryBar_1gl4p_41 ._collapsible_1gl4p_53 ._collapsedContent_1gl4p_58 {
  border-top: 1px solid #434861;
  padding-top: 4px;
  margin-top: 4px;
}
._container_1gl4p_22 ._primaryBar_1gl4p_41 ._primaryContent_1gl4p_63 {
  flex-grow: 1;
  gap: 8px;
  display: flex;
  width: 100%;
  align-items: center;
}
._container_1gl4p_22 ._primaryBar_1gl4p_41 ._primaryContent_1gl4p_63 ._children_1gl4p_70 {
  flex-grow: 1;
}
._container_1gl4p_22 ._primaryBar_1gl4p_41 ._primaryContent_1gl4p_63 ._caret_1gl4p_73 {
  flex-shrink: 1;
  min-width: 20px;
  align-self: flex-start;
}
._container_1gl4p_22:hover [class*=outOfBoundsIndicator] {
  box-shadow: 0 0 0 2px #2e3247;
}

._hoverContainer_1gl4p_82 {
  border-left: 1px solid #5a5f7a;
}