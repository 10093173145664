:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._leading_yx5gc_7 {
  display: flex;
  margin-top: 1px;
  flex: none;
}
._leading_yx5gc_7 ._waterfallPill_yx5gc_12 {
  flex: none;
  min-width: 40px;
  height: 16px;
}

._waterfallPillPopper_yx5gc_18 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 16px;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5a5f7a;
}

._networkResponse_yx5gc_30 {
  display: flex;
  flex: 1;
  align-items: center;
  font-size: 12px;
  line-height: 16px;
}
._networkResponse_yx5gc_30 ._networkResponseMethod_yx5gc_37 {
  flex: none;
  width: auto;
  font-weight: 500;
  color: #bfc2d4;
}
._networkResponse_yx5gc_30 ._networkResponseMethod_yx5gc_37._ghost_yx5gc_43 {
  color: #9095ad;
}
._networkResponse_yx5gc_30 ._networkResponsePath_yx5gc_46 {
  display: flex;
  align-items: center;
  gap: 6px;
  margin-left: 6px;
  font-weight: 400;
  color: #bfc2d4;
}
._networkResponse_yx5gc_30 ._networkResponsePath_yx5gc_46 ._networkResponseIconContainer_yx5gc_54 {
  min-width: 16px;
  height: 16px;
}
._networkResponse_yx5gc_30 ._networkResponsePath_yx5gc_46 ._networkResponsePathContainer_yx5gc_58 {
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._networkResponse_yx5gc_30 ._networkResponsePath_yx5gc_46._ghost_yx5gc_43 {
  color: #9095ad;
}

._networkStatus_yx5gc_68 {
  display: flex;
}
._networkStatus_yx5gc_68 ._networkIcon_yx5gc_71 {
  display: flex;
  min-width: 14px;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
}
._networkStatus_yx5gc_68 ._networkIcon_yx5gc_71 ._networkResponseCircle_yx5gc_78 {
  flex: none;
  width: 10px;
  height: 10px;
  border: 3px solid;
  border-radius: 50%;
  position: relative;
}

._networkResponseStatusCode_yx5gc_87 {
  margin-left: 6px;
  flex: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

._outOfBoundsTooltip_yx5gc_95 {
  max-width: 340px;
  text-align: left;
  font-size: 14px;
  line-height: 20px;
  color: #5a5f7a;
  margin: 12px 16px;
}
._outOfBoundsTooltip_yx5gc_95 ._divider_yx5gc_103 {
  display: flex;
  margin: 6px 0;
  width: 100%;
  border: 1px solid #e1e3ed;
}