:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._container_wy05n_7 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  border: 1px solid #434861;
  background: #2e3247;
  border-radius: 4px;
  height: 30px;
  min-width: 100%;
  width: 100%;
  font-size: 12px;
}

._message_wy05n_21 {
  color: #d0d2e0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

._details_wy05n_28 {
  display: flex;
  align-items: center;
  gap: 10px;
}
._details_wy05n_28 ._size_wy05n_33 {
  color: #bfc2d4;
}
._details_wy05n_28 ._icon_wy05n_36 {
  height: 19px;
  color: #bfc2d4;
}