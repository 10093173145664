:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._container_15cij_7 {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 24px;
}
._container_15cij_7 ._noResults_15cij_15 {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  color: #d0d2e0;
}
._container_15cij_7 ._clearFiltersBtn_15cij_22 {
  background: transparent;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}