@import '@frontend/design-system/src/styles/index.scss';
// @import '@frontend/dashboard/src/lib/_general.scss';
// @import '@frontend/dashboard/src/main.scss';
@import '@frontend/dashboard/src/common/code-snippet/CodeSnippet.scss';

// this file is used when developing the reporter in isolation via cypress tests
// if you update this file, also update main-runner.scss
@import '../submodules/reporter/lib/variables';
@import '../submodules/reporter/lib/mixins';
// @import "../submodules/reporter/lib/fonts";
@import '../submodules/reporter/lib/base';
@import '../submodules/reporter/lib/switch';
@import '../submodules/reporter/lib/tag';
// @import '../submodules/reporter/lib/tooltip';
@import '~@reach/dialog/styles.css';

@import '../submodules/reporter/errors/errors.scss';
// @import "../submodules/reporter/errors/prism.scss";
// import all other scss files in src except if they are in lib
// or their file name is `selecor-playground` or `main`
// NOTA: no need to import scss files in their components
// @import '../../reporter/!(lib)*/**/!(main)*.scss' ;

@import '../submodules/reporter/preferences/testing-preferences.scss';
@import '../submodules/reporter/runnables/runnables.scss';
@import '../submodules/reporter/instruments/instruments.scss';
@import '../submodules/reporter/sessions/sessions.scss';
// @import "../submodules/reporter/main.scss";
@import '../submodules/reporter/collapsible/collapsible.scss';
@import '../submodules/reporter/hooks/hooks.scss';
// @import "../submodules/reporter/main-runner.scss";
@import '../submodules/reporter/lib/switch.scss';
@import '../submodules/reporter/attempts/attempts.scss';
@import '../submodules/reporter/commands/commands.scss';
// @import "../submodules/reporter/errors/prism.scss";
@import '../submodules/reporter/errors/errors.scss';
@import '../submodules/reporter/header/DebugDismiss.scss';
@import '../submodules/reporter/header/header.scss';

// Forced styles on the App's Reporter
// to support the TestReplay specific ux:
@import './overrides.scss';
