._tabs-shim_1as1l_1 button,
._tabs-shim_1as1l_1 input[type=submit],
._tabs-shim_1as1l_1 input[type=reset] {
  background: none;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
._tabs-shim_1as1l_1 [role=tablist] {
  height: 32px;
  border-style: solid;
}