:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._container_1bxr9_7 {
  height: 100%;
  display: flex;
  flex-direction: column;
}
._container_1bxr9_7 ._header_1bxr9_12 {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 12px 16px;
  gap: 8px;
  border-bottom: 1px solid #434861;
}
._container_1bxr9_7 ._header_1bxr9_12 ._networkItem_1bxr9_20 {
  background-color: transparent;
  padding: 0;
}
._container_1bxr9_7 ._body_1bxr9_24 {
  height: 100%;
  overflow: hidden;
  padding: 12px 16px;
}
._container_1bxr9_7 ._body_1bxr9_24 ._requestInfo_1bxr9_29 {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #d0d2e0;
}
._container_1bxr9_7 ._body_1bxr9_24 ._requestInfo_1bxr9_29 ._requestInfoValue_1bxr9_37 {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  row-gap: 0px;
}
._container_1bxr9_7 ._body_1bxr9_24 ._requestInfo_1bxr9_29 ._requestInfoValue_1bxr9_37 ._requestInfoValueTitle_1bxr9_43 {
  color: #9095ad;
}
._container_1bxr9_7 ._body_1bxr9_24 ._requestInfo_1bxr9_29 ._dotDivider_1bxr9_46 {
  margin: 0 4px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #5a5f7a;
}
._container_1bxr9_7 ._tabs_1bxr9_53 {
  margin-top: 12px;
  flex-grow: 1;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
._container_1bxr9_7 ._tabs_1bxr9_53 button,
._container_1bxr9_7 ._tabs_1bxr9_53 input[type=submit],
._container_1bxr9_7 ._tabs_1bxr9_53 input[type=reset] {
  background: transparent;
  color: inherit;
  border: none;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}
._container_1bxr9_7 ._tabs_1bxr9_53 [role=tab]:not([aria-selected=true]):hover {
  background: #1b1e2e;
}
._container_1bxr9_7 ._tabs_1bxr9_53 [aria-selected=true] {
  color: #ffffff;
}
._container_1bxr9_7 ._tabs_1bxr9_53 [role=tablist] {
  background: transparent;
  height: 32px;
  border: 1px solid #5a5f7a;
}
._container_1bxr9_7 ._content_1bxr9_81 {
  height: calc(100% - 64px);
  position: relative;
}
._container_1bxr9_7 ._contentHeader_1bxr9_85 {
  position: absolute;
  top: 0;
  z-index: 1;
  height: 16px;
  width: 100%;
  background: linear-gradient(180deg, #1b1e2e 0%, rgba(27, 30, 46, 0) 100%);
}
._container_1bxr9_7 ._contentBody_1bxr9_93 {
  height: 100%;
  overflow: auto;
}
._container_1bxr9_7 ._contentBody_1bxr9_93 ._collapsingDiv_1bxr9_97 {
  margin-top: 12px;
  display: grid;
  grid-template-columns: 20px auto;
  grid-template-areas: "icon title" ". content";
}
._container_1bxr9_7 ._contentBody_1bxr9_93 ._collapsingDiv_1bxr9_97 ._collapsingDivTitleIcon_1bxr9_103 {
  cursor: pointer;
  grid-area: icon;
}
._container_1bxr9_7 ._contentBody_1bxr9_93 ._collapsingDiv_1bxr9_97 ._collapsingDivTitle_1bxr9_103 {
  cursor: pointer;
  grid-area: title;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #bfc2d4;
}
._container_1bxr9_7 ._contentBody_1bxr9_93 ._collapsingDiv_1bxr9_97 ._collapsingDivContent_1bxr9_115 {
  grid-area: content;
}
._container_1bxr9_7 ._contentBody_1bxr9_93 ._collapsingDiv_1bxr9_97 ._collapsingDivCodeContent_1bxr9_118 {
  margin-top: 8px;
  grid-column: 1/span 2;
}
._container_1bxr9_7 ._contentBody_1bxr9_93::-webkit-scrollbar {
  width: 0;
  height: 0;
}
._container_1bxr9_7 ._requestContent_1bxr9_126 ._requestContentKey_1bxr9_126 {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #c8a7f5;
}
._container_1bxr9_7 ._requestContent_1bxr9_126 ._requestContentNetworkStatus_1bxr9_132 {
  display: inline-flex;
}
._container_1bxr9_7 ._requestContent_1bxr9_126 ._requestContentValue_1bxr9_135 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #bfc2d4;
}

._tooltipContainer_1bxr9_142 {
  max-width: 300px;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5a5f7a;
}

._alert-info_1bxr9_151 {
  font-weight: 400;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 12px;
  border: 1px solid #434861;
  background: #2e3247;
  color: #d0d2e0;
}

._alert-danger_1bxr9_161 {
  font-weight: 400;
  border-radius: 4px;
  padding: 6px 8px;
  font-size: 12px;
  background: #f3ecb3;
  color: #702c00;
}
._alert-danger_1bxr9_161 ._learn-more_1bxr9_169 {
  color: #4956e3;
}