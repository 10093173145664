:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._errorContentOverride_uct6v_7 {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
._errorContentOverride_uct6v_7 ._errorContentWrapper_uct6v_14 {
  margin-top: -70px;
  width: 440px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
._errorContentOverride_uct6v_7 ._errorContentWrapper_uct6v_14 h2 {
  color: #d0d2e0;
  font-size: 18px;
  margin-top: 30px;
}
._errorContentOverride_uct6v_7 ._errorContentWrapper_uct6v_14 span {
  color: #d0d2e0;
  margin-top: 10px;
}
._errorContentOverride_uct6v_7 ._errorContentWrapper_uct6v_14 button {
  color: #d0d2e0;
  margin-top: 24px;
  background-color: inherit;
  border: 1px solid #747994;
}
._errorContentOverride_uct6v_7 ._errorContentWrapper_uct6v_14 a {
  text-decoration: none;
}
._errorContentOverride_uct6v_7 ._errorContentWrapper_uct6v_14 a :hover {
  text-decoration: none;
}