:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._resizable-sidebar_1vr1c_7 {
  height: 100%;
  display: flex;
  position: relative;
  flex-direction: column;
  z-index: 1;
}

._resizable-sidebar-resizer_1vr1c_15 {
  transition: all ease-in 0.1s;
  resize: horizontal;
  cursor: ew-resize;
  position: absolute;
  height: 100%;
  width: 5px;
  z-index: 1;
}
._resizable-sidebar-resizer_1vr1c_15._right_1vr1c_24 {
  right: -4px;
}
._resizable-sidebar-resizer_1vr1c_15._left_1vr1c_27 {
  left: -4px;
}

._light_1vr1c_31 ._resizable-sidebar-resizer_1vr1c_15:hover,
._light_1vr1c_31 ._resizable-sidebar-resizer_1vr1c_15._active_1vr1c_32 {
  background-color: #4956e3;
}

._dark_1vr1c_36 ._resizable-sidebar-resizer_1vr1c_15:hover,
._dark_1vr1c_36 ._resizable-sidebar-resizer_1vr1c_15._active_1vr1c_32 {
  background-color: #6470f3;
}