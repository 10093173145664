@import '@frontend/design-system/src/styles/_variables.scss';

.test-replay {
  .copy-btn-container {
    height: 20px;
    width: 20px;
    margin: 8px;
  }

  .col-nums {
    background-color: $gray-900 !important;
  }

  .col-code {
    background-color: $gray-950 !important;
  }

  .code-snippet-button-wrapper {
    &:hover {
      box-shadow: none !important;
    }

    button {
      padding: 2px;
      height: 20px !important;
      color: $gray-500 !important;
      background-color: $gray-950 !important;
      border: none;
      box-shadow: none !important;

      &:hover {
        box-shadow: 0 0px 0 2px rgb(255 255 255 / 0.2) !important;
        border-radius: 4px;
      }
    }
  }

  .copy-icon {
    margin-right: 0 !important;
  }
}

.test-replay-code-snippet {
  .rc-tooltip-inner {
    background-color: $gray-A1;
    color: $gray-700;
  }

  .rc-tooltip-arrow {
    bottom: 5px;
    border-top-color: $gray-A1 !important;
  }
}
