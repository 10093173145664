:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._tr-play-icon_1rbk6_7 {
  pointer-events: none;
}
._tr-play-icon_1rbk6_7 * {
  fill: #1b1e2e;
  stroke: #1b1e2e;
  height: 16px;
  width: 16px;
}
._tr-play-icon-disabled_1rbk6_16 * {
  fill: #bfc2d4;
  stroke: #bfc2d4;
}

._tr-play-button_1rbk6_21 {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 100%;
  width: 34px;
  height: 32px;
  min-width: 34px;
  min-height: 32px;
  border: 1px solid #e1e3ed;
  background-color: white;
  transition: all;
}
._tr-play-button_1rbk6_21:hover {
  border: 1px solid #d0d2e0;
  box-shadow: 0 0 0px 3px #f3f4fa;
}
._tr-play-button_1rbk6_21:active {
  border: 1px solid #747994;
}
._tr-play-button_1rbk6_21:focus {
  box-sizing: border-box;
  border: 2px solid #5a5f7a !important;
}
._tr-play-button-small_1rbk6_48 {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}
._tr-play-button-small_1rbk6_48:hover {
  border: 1px solid #d0d2e0;
  box-shadow: 0 0 0px 2px #e1e3ed;
}
._tr-play-button-small_1rbk6_48 svg {
  margin: 0px !important;
}
._tr-play-button-small_1rbk6_48 svg * {
  stroke-width: 3px;
  stroke: #6470f3;
  fill: #c5c9fd;
}

._active-highlight_1rbk6_67 {
  box-sizing: border-box;
  border: 2px solid #5a5f7a !important;
}