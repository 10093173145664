// Handle styles for rounded borders
// on headers, collapsible, commands, etc:
.hook-header {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
  padding: 0 4px;
}
// collapsed
.collapsible .collapsible > :last-child {
  border-radius: 4px !important;
}
// expanded
.collapsible.is-open .collapsible.is-open > :last-child {
  border: 1px solid $gray-900;
  border-radius: 4px;
}
// expanded and hovered
.collapsible-header-wrapper:hover + .collapsible-content {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.commands-container {
  border-radius: 4px !important;
  .command:first-child {
    .command-wrapper {
      border-top-left-radius: 3px !important;
      border-top-right-radius: 3px !important;
    }
  }
  .command:last-child {
    .command-wrapper {
      border-bottom-left-radius: 3px !important;
      border-bottom-right-radius: 3px !important;
    }
  }
}

// Ghost style for test-body content, e.g.,
// before-each, test-body, after-each, etc
@mixin ghost-style {
  opacity: 0.8;
  color: $gray-500 !important;
}
.tr-session-status-pending {
  @include ghost-style();
}

.test-replay--ghost-boundary,
.test-replay--ghost-boundary ~ * {
  .command {
    .reporter-tag {
      @include ghost-style();
      background-color: $gray-800 !important;
    }
  }
  .command-state-passed {
    .command-number-column {
      @include ghost-style();
    }
  }
  .command-state-failed {
    .command-number-column {
      @include ghost-style();
    }
  }
  .command-method {
    span {
      @include ghost-style();
      background-color: transparent !important;
    }
  }
  .command-message {
    @include ghost-style();
    * {
      @include ghost-style();
    }
  }
  .command-info {
    @include ghost-style();
    * {
      @include ghost-style();
    }
  }
}
