:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._container_1da9a_7 {
  outline: none;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  border: 1px solid #434861;
  background-color: #25283c;
  transition: all 0.1s ease;
  overflow: hidden;
}
._container_1da9a_7 ._marker_1da9a_19 {
  position: absolute;
  height: 100%;
  border-style: solid;
  border-radius: 4px;
  border-width: 1px;
  z-index: 1;
}
._container_1da9a_7 ._marker_1da9a_19 ._bg_1da9a_27 {
  position: absolute;
  z-index: 0;
  border: none;
  border-radius: 4px;
  height: calc(100% + 2px);
  width: calc(100% + 2px);
  left: -1px;
  top: -1px;
}
._container_1da9a_7 ._marker_1da9a_19 ._isRange_1da9a_37 {
  border-radius: 2px;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  opacity: 0.25;
}
._container_1da9a_7 ._border_1da9a_45 {
  position: absolute;
  z-index: 1;
  height: 100%;
  border-radius: 4px;
}

._hoverable_1da9a_52:hover {
  box-shadow: 0px 0px 0px 2px rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.6);
}

._warningWrapper_1da9a_57 {
  height: 100%;
  position: relative;
}
._warningWrapper_1da9a_57 ._warningDot_1da9a_61 {
  position: absolute;
  background-color: #edbb4a;
  border-radius: 100%;
  height: 6px;
  width: 6px;
  z-index: 2;
  right: -6%;
  top: 80%;
  box-shadow: 0 0 0 2px #1b1e2e;
}