@import '@frontend/design-system/src/styles/_variables';

/**
 * base styles
 * (light-mode designs)
 */
.single-select-custom-element {
  border-radius: 4px;
  border: 1px solid transparent;

  &:hover,
  &:focus-within {
    border: 1px solid $indigo-300;
    box-shadow: 0 0 0 2px $indigo-100;
    svg {
      fill: $indigo-500;
    }
  }

  .custom-value-container {
    cursor: pointer;
    display: block;
  }

  .custom-value-wrapper {
    display: flex;
    align-items: center;
  }

  .custom-single-value {
    width: 100%;
  }

  .custom-control {
    box-shadow: none;
    border: none;
    .custom-clear {
      svg {
        cursor: pointer;
        color: $gray-300;
      }
    }
    .custom-svg-container {
      cursor: pointer;
      fill: $gray-500;
    }
  }
  .custom-opts-container {
    font-size: 14px;
    font-weight: 400;
    border: 1px solid $indigo-300;
    box-shadow: 0 0 0 2px $indigo-100;
    border-radius: 4px;
    padding: 3px;
    .custom-opt {
      cursor: pointer;
      color: $indigo-500;
      padding: 4px;
      font-size: 14px;
      border-radius: 4px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .custom-opt-label {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 12px;
      }
      label {
        padding: 0;
        margin: 0;
        font-weight: normal;
      }
      &:hover {
        background-color: $indigo-50;
      }
    }
    .custom-opt-disabled {
      &:hover {
        background-color: transparent;
      }
    }
  }
  .no-shadow {
    box-shadow: none;
  }
}
