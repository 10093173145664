._attempt-container_1gpwn_1 {
  height: 100%;
  padding-left: 12px;
}
._attempt-container_1gpwn_1 .reporter {
  height: 100%;
  padding: 4px 0;
}
._attempt-container_1gpwn_1 .runnable {
  height: 100%;
}