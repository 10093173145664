:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._container_xi8mo_7 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1b1e2e;
}
._container_xi8mo_7 ._console-filters_xi8mo_14 {
  display: flex;
  align-items: center;
  gap: 8px;
  min-height: 64px;
  height: 64px;
  padding: 16px;
  border-bottom: 1px solid #2e3247;
}
._container_xi8mo_7 ._console-filters_xi8mo_14 ._search-input-filter_xi8mo_23 {
  flex: 1;
}
._container_xi8mo_7 ._list_xi8mo_26 {
  height: 100%;
  padding-top: 12px;
  overflow: auto;
  overflow-x: clip;
}
._container_xi8mo_7 ._consoleCallFrames_xi8mo_32 td {
  padding-left: 2px;
  padding-right: 2px;
}
._container_xi8mo_7 ._consoleCallFrames_xi8mo_32 tr :first-child {
  overflow-wrap: anywhere;
}
._container_xi8mo_7 ._consoleCallFrames_xi8mo_32._ghost_xi8mo_39 {
  color: #9095ad;
}

._emptyContainer_xi8mo_43 {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1b1e2e;
  align-items: center;
  width: 100%;
  justify-content: center;
}
._emptyContainer_xi8mo_43 ._emptyStateDescription_xi8mo_52 {
  margin-top: 16px;
  font-size: 14px;
  line-height: 20px;
  color: #d0d2e0;
}