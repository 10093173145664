:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._scrubber-container_frhir_7 {
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  width: 100%;
}

._input-track-layers_frhir_15 {
  width: 100%;
  height: 100%;
  padding-top: 12px;
}

._scrubber-input_frhir_21 {
  border: transparent !important;
  box-shadow: none !important;
  position: absolute;
  background-color: transparent;
  appearance: none;
  cursor: pointer;
  outline: none;
  height: 100%;
  z-index: 3;
}
._scrubber-input_frhir_21::-webkit-slider-runnable-track {
  height: 100%;
  opacity: 0;
}
._scrubber-input_frhir_21::-moz-range-thumb {
  opacity: 0;
  outline: none;
}
._scrubber-input_frhir_21::-webkit-slider-thumb {
  outline: none;
}

._scrubber-tracks-container_frhir_44 {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  height: 8px;
}
._scrubber-tracks-container_frhir_44 div {
  position: absolute;
  height: 8px;
}
._scrubber-tracks-container_frhir_44 ._passive-track_frhir_54 {
  width: 100%;
  background-color: #e1e3ed;
}
._scrubber-tracks-container_frhir_44 ._active-track_frhir_58 {
  background-color: #6470f3;
}
._scrubber-tracks-container_frhir_44 ._cliff-track_frhir_61 {
  background-color: #e45770;
  border-left: 1px solid white;
  box-sizing: border-box;
  width: 100%;
  right: 0;
}