:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._tr-timer-display-container_1w2sh_7 {
  font-size: 14px;
  font-weight: 400;
  color: #5a5f7a;
  text-align: center;
  width: 105px;
  min-width: 105px;
  text-align: center;
  margin: 0 -5px;
}