:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._mock-thumb-container_1tp1t_7 {
  pointer-events: none;
  position: relative;
  width: 100%;
}

._mock-thumb_1tp1t_7 {
  height: 32px;
  border-radius: 2px;
  position: relative;
}
._mock-thumb_1tp1t_7 ._pads_1tp1t_18 {
  background: white;
  position: absolute;
  bottom: 11px;
  height: 8px;
  width: 1px;
  opacity: 0.5;
}

._mock-thumb-dynamic_1tp1t_27 {
  position: absolute;
  margin-top: -21px;
  z-index: 3;
}

._thumb-hit-box_1tp1t_33 {
  position: relative;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  background: #bfc2d4;
  margin-left: -3px;
  height: 5px;
  width: 8px;
}
._thumb-hit-box_1tp1t_33 ._scrubber-time_1tp1t_42 {
  pointer-events: none;
  position: absolute;
  font-weight: 500;
  font-size: 11px;
  color: #5a5f7a;
}

._thumb-hit-peak_1tp1t_50 {
  width: 0;
  height: 0;
  margin: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  margin-bottom: -3px;
  margin-left: -3px;
}

._passing_1tp1t_60 ._mock-thumb_1tp1t_7 {
  background: #6470f3;
}
._passing_1tp1t_60 ._thumb-hit-box_1tp1t_33 {
  background: #6470f3;
}
._passing_1tp1t_60 ._thumb-hit-peak_1tp1t_50 {
  border-top: 4px solid #6470f3;
}

._failing_1tp1t_70 ._mock-thumb_1tp1t_7 {
  background: #e45770;
}
._failing_1tp1t_70 ._thumb-hit-box_1tp1t_33 {
  background: #e45770;
}
._failing_1tp1t_70 ._thumb-hit-peak_1tp1t_50 {
  border-top: 4px solid #e45770;
}

._ghost_1tp1t_80 ._mock-thumb_1tp1t_7 {
  background: #9095ad;
  mix-blend-mode: multiply;
}
._ghost_1tp1t_80 ._thumb-hit-box_1tp1t_33 {
  background: #9095ad;
}
._ghost_1tp1t_80 ._thumb-hit-peak_1tp1t_50 {
  border-top: 4px solid #9095ad;
}
._ghost-light-mode_1tp1t_90 ._mock-thumb_1tp1t_7 {
  background: #bfc2d4;
}
._ghost-light-mode_1tp1t_90 ._thumb-hit-box_1tp1t_33 {
  background: #bfc2d4;
}
._ghost-light-mode_1tp1t_90 ._thumb-hit-peak_1tp1t_50 {
  border-top: 4px solid #bfc2d4;
}

._ghost-range-container_1tp1t_100 {
  display: flex;
  pointer-events: none;
  justify-content: space-between;
  margin-top: -21px;
  mix-blend-mode: multiply;
  background: #e1e3ed;
  position: relative;
  height: 32px;
}
._ghost-range-container_1tp1t_100 ._mock-thumb_1tp1t_7 {
  mix-blend-mode: normal;
}