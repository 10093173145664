.reporter-tag {
  border-radius: 4px;
  color: $gray-100;
  display: inline-block;
  font-family: $monospace;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  height: 18px;
  max-width: 200px;
  overflow: hidden;
  padding: 0 4px;
  text-overflow: ellipsis;
  vertical-align: text-top;
  white-space: nowrap;
}

.reporter-tag.reporter-tag-content {
  &.route, // alias for xhr stubs/spies through cy.intercept()
  &.agent, // alias for spies/stubs through cy.spy() and cy.stub()
  &.primitive { // default
    background-color: $purple-500;
  }

  &.dom { // alias for dom reference
    background-color: $indigo-500;
  }

  &.count { // num of children or num of element tag
    background-color: rgba($gray-600, .25);
  }

  &.successful-status {
    background-color: $gray-1000;
    border: $gray-900 1px solid;
    color: $jade-300;
  }

  &.warned-status {
    background-color: $gray-1000;
    border: $gray-900 1px solid;
    color: $warn-text;
  }

  &.failed-status {
    background-color: $red-500;
  }

  &.reporter-tag-has-count {
    border-radius: 4px 0 0 4px;
    padding: 0 2px 0 4px;
  }
}

.reporter-tag.reporter-tag-count {
  border-radius: 0 4px 4px 0;

  &.route, // alias for xhr stubs/spies through cy.intercept()
  &.agent, // alias for spies/stubs through cy.spy() and cy.stub()
  &.primitive { // default
    background-color: $purple-400;
  }

  &.dom { // alias for dom reference
    background-color: $indigo-400;
  }
}

.session-item:hover > .session-tag > .reporter-tag.successful-status,
.command-wrapper:hover > .command-pin-target > .command-wrapper-text > .command-controls > .reporter-tag.successful-status {
  border-color: $gray-700;
}
