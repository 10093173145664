:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._aut-controls-container_cn2zu_7 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 4px;
  background-color: #1b1e2e;
  color: #e1e3ed;
  padding: 8px;
  height: 40px;
}

._leading_cn2zu_19 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
._leading_cn2zu_19 ._title_cn2zu_25 {
  font-size: 14px;
  color: #5a5f7a;
  padding: 0px 8px;
}

._button-list_cn2zu_31 {
  display: flex;
  flex-wrap: nowrap;
  border-radius: 4px;
  height: 24px;
}
._button-list_cn2zu_31 button {
  all: unset;
  background-color: #2e3247;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0 12px;
  height: 100%;
}
._button-list_cn2zu_31 button:hover, ._button-list_cn2zu_31 button:focus {
  border: 1px solid #decbf8 !important;
}
._button-list_cn2zu_31 button:hover, ._button-list_cn2zu_31 button:focus {
  padding: 0 12px 0 11px;
}
._button-list_cn2zu_31 button:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
._button-list_cn2zu_31 button:not(:last-child) {
  border-right: 1px solid #5a5f7a;
}
._button-list_cn2zu_31 button:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-right: 1px solid transparent;
}
._button-list_cn2zu_31 button._active_cn2zu_63 {
  background-color: #7f43c9;
}

._highlights-controls_cn2zu_67 {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  gap: 12px;
  background-color: #2e3247;
  padding: 4px 12px;
  height: 24px;
}

._close-button_cn2zu_78 {
  all: unset;
  background-color: #2e3247;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border-radius: 4px;
  height: 24px;
  width: 24px;
}
._close-button_cn2zu_78:hover, ._close-button_cn2zu_78:focus {
  border: 1px solid #decbf8 !important;
}

._custom-switch_cn2zu_95 {
  all: unset;
  cursor: pointer;
  height: 11px;
  width: 20px;
  box-sizing: border-box;
  background: #9095ad;
  border-radius: 8px;
  padding: 1px 2px;
  transition: box-shadow 0.15s ease-in-out;
}
._custom-switch_cn2zu_95:hover, ._custom-switch_cn2zu_95:focus {
  border: 1px solid #c8a7f5;
  box-shadow: 0px 0px 0px 2px #e9ddfa;
  padding: 1px;
}
._custom-switch_cn2zu_95 ._lever_cn2zu_111 {
  transition: all ease-in 0.15s;
  border-radius: 100%;
  background: white;
  height: 7px;
  width: 7px;
}
._custom-switch_cn2zu_95._active_cn2zu_63 {
  background: #1fa971;
}
._custom-switch_cn2zu_95._active_cn2zu_63 ._lever_cn2zu_111 {
  margin-left: 9px;
}