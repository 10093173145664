:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._tr-option_qdp2g_7 svg path {
  fill: #f3f4fa;
  stroke: #9095ad;
}
._tr-option-selected_qdp2g_11 svg path {
  stroke: #4956e3;
}

._tr-speed-option_qdp2g_15 {
  width: 100%;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #434861;
}
._tr-speed-option_qdp2g_15 ._speed-value-display_qdp2g_23 {
  display: flex;
  justify-content: flex-start;
  gap: 8px;
}
._tr-speed-option_qdp2g_15 ._checkmark-container_qdp2g_28 {
  color: #9aa2fc;
}
._tr-speed-option_qdp2g_15 svg {
  color: #4956e3;
  margin-bottom: -3px;
  fill: none !important;
}

._tr-speed-picker-wrapper_qdp2g_37 {
  color: #434861;
  border: 1px solid #e1e3ed;
  border-radius: 4px;
  min-width: 110px;
  width: 110px;
}
._tr-speed-picker-wrapper_qdp2g_37 .custom-opts-container {
  width: 180px;
  border: 1px solid #e1e3ed;
  background-color: white;
  margin-left: -73px !important;
  padding: 6px !important;
}
._tr-speed-picker-wrapper_qdp2g_37 .custom-opts-container .custom-options-title {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 4px;
  padding: 8px 6px 0 6px;
  color: #1b1e2e;
}
._tr-speed-picker-wrapper_qdp2g_37 .custom-opts-container .custom-single-value {
  color: #e1e3ed !important;
}
._tr-speed-picker-wrapper_qdp2g_37 .custom-control {
  height: 28px;
  display: flex;
  align-items: center;
}
._tr-speed-picker-wrapper_qdp2g_37 .custom-svg-container {
  height: 28px !important;
}
._tr-speed-picker-wrapper_qdp2g_37 .custom-svg-container * {
  height: 28px !important;
}
._tr-speed-picker-wrapper_qdp2g_37 .custom-svg-container svg {
  height: 20px !important;
  margin-top: -4px !important;
}