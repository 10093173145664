:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._search-input-container_10068_7 {
  transition: all;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0px 14px;
  border-radius: 4px;
  border: 1px solid #434861;
  background-color: #2e3247;
  height: 32px;
}
._search-input-container_10068_7 input {
  all: unset;
  width: 100%;
  height: 100%;
  color: #afb3c7;
}
._search-input-container_10068_7:hover {
  box-shadow: 0px 0px 0px 1px #2e3247;
}
._search-input-container_10068_7:focus-within {
  border: 1.5px solid #9aa2fc;
  box-shadow: 0px 0px 0px 1px #2f3ab0;
}
._search-input-container_10068_7:active {
  border: 1px solid #9aa2fc;
  box-shadow: 0px 0px 0px 1px #2f3ab0;
}

._icon-container_10068_37 {
  height: 16px;
  width: 16px;
}
._icon-container_10068_37 svg {
  fill: #434861;
}
._icon-container_10068_37 svg path {
  stroke: #afb3c7 !important;
}
._icon-container_10068_37 button {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 16px;
  width: 16px;
}
._icon-container_10068_37 button:active svg path {
  stroke: #e1e3ed !important;
}