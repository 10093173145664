:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._status-icon-container_1b2e9_7 {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #2e3247;
  padding-right: 12px;
  min-width: 32px;
}

._command-logs-container_1b2e9_16 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1b1e2e;
  border-right: 1px solid #2e3247;
}
._command-logs-container_1b2e9_16 ._header-container_1b2e9_24 {
  border-bottom: 1px solid #2e3247;
  padding: 12px;
  height: 64px;
  min-height: 64px;
  display: flex;
  gap: 10px;
}
._command-logs-container_1b2e9_16 ._header-container_1b2e9_24 ._titles-container_1b2e9_32 {
  margin-top: -2px;
}
._command-logs-container_1b2e9_16 ._header-container_1b2e9_24 ._titles-container_1b2e9_32 ._title_1b2e9_32 {
  color: #747994;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._command-logs-container_1b2e9_16 ._header-container_1b2e9_24 ._titles-container_1b2e9_32 ._title_1b2e9_32 svg {
  margin: 0 5px;
  margin-bottom: -3px;
}
._command-logs-container_1b2e9_16 ._header-container_1b2e9_24 ._titles-container_1b2e9_32 ._subtitle_1b2e9_47 {
  color: white;
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  direction: rtl;
  text-align: left;
}
._command-logs-container_1b2e9_16 ._attempt-wrapper_1b2e9_57 {
  height: 100%;
}

._tooltip-container_1b2e9_61 {
  text-align: left;
}
._tooltip-container_1b2e9_61 ._tooltip-title-row_1b2e9_64 {
  padding-top: 8px;
  padding-left: 15px;
  border-left: 1px dotted #bfc2d4;
}
._tooltip-container_1b2e9_61 ._tooltip-title_1b2e9_64 {
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5a5f7a;
}
._tooltip-container_1b2e9_61 ._tooltip-title-section_1b2e9_78 {
  padding: 6px 8px;
  border-bottom: 1px solid #f3f4fa;
  margin-bottom: 8px;
}
._tooltip-container_1b2e9_61 ._tooltip-title-chevron_1b2e9_83 {
  margin-left: -7px;
  margin-right: 6px;
}