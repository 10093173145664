.reporter {
  .hooks-container {
    .hook-item {
      margin-bottom: 5px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .hook-header {
      font-family: $font-system;
      display: flex;
      width: 100%;

      &:hover {
        background-color: $gray-900;

        .hook-open-in-ide {
          cursor: pointer;
          display: block;
        }
      }

      .collapsible-header {
        text-transform: uppercase;
        color: $gray-400;
        display: inline-block;
        flex-grow: 1;
        font-size: 11px;
        cursor: pointer;
        padding: 4px 0;

        &:focus {
          outline: none;
        }

        > .collapsible-header-inner:focus {
          outline: 0;
        }
      }

      .hook-failed-message {
        color: $red-400;
      }

      .hook-open-in-ide {
        align-items: center;
        color: $gray-400;
        display: none;
        padding: 4px;

        &:hover, &:focus {
          background-color: rgba($gray-700, 0.2);
          outline: none;
          text-decoration: none;
        }

        svg {
          vertical-align: middle;

          path:nth-child(2) {
            fill: $gray-800;
          }
        }
      }
    }
  }
}
