:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._status-icon-container_1qslx_7 {
  width: 16px;
  height: 16px;
  margin-right: 4px;
}

._status-color-passed_1qslx_13 {
  color: #1fa971;
}
._status-color-failed_1qslx_16 {
  color: #e45770;
}

._single-select-wrapper_1qslx_20 .single-select-custom-element {
  transition: all;
  border: 1px solid #e1e3ed !important;
  box-shadow: none;
  border-radius: 24px;
}
._single-select-wrapper_1qslx_20 .single-select-custom-element:hover {
  border: 1px solid #d0d2e0 !important;
  box-shadow: 0 0 0px 3px #f3f4fa;
}
._single-select-wrapper_1qslx_20 .single-select-custom-element:active {
  border: 2px solid #747994 !important;
}
._single-select-wrapper_1qslx_20 .single-select-custom-element:focus-within {
  border: 2px solid #5a5f7a !important;
}
._single-select-wrapper_1qslx_20 .single-select-custom-element .custom-control {
  flex-wrap: nowrap;
  border-radius: 24px;
  min-height: 30px;
  height: 30px;
}
._single-select-wrapper_1qslx_20 .single-select-custom-element .custom-opts-container {
  border: 1px solid #e1e3ed !important;
}
._single-select-wrapper_1qslx_20 ._attempt-list-item-container_1qslx_45 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
._single-select-wrapper_1qslx_20 ._attempt-list-item-container_1qslx_45 ._att-number_1qslx_52 {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  color: #2e3247 !important;
  margin-left: -4px;
}
._single-select-wrapper_1qslx_20 ._attempt-list-item-container_1qslx_45:hover ._base-time_1qslx_59 {
  flex: 1;
  background: #c5c9fd;
  height: 8px;
}
._single-select-wrapper_1qslx_20 ._attempt-list-item-container_1qslx_45:hover ._duration-container_1qslx_64 {
  border: 1px solid #c5c9fd;
}
._single-select-wrapper_1qslx_20 ._attempt-list-item-container_1qslx_45 svg {
  margin-right: 10px;
  margin-bottom: -1px;
  fill: none !important;
}

._duration-container_1qslx_64 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5a5f7a;
  border-radius: 14px;
  border: 1px solid #e1e3ed;
  font-size: 12px;
  font-weight: 400;
  height: 20px;
  width: 50px;
}

._checkmark-container_1qslx_86 {
  color: #6470f3;
  margin-right: -6px;
  margin-bottom: -2px;
}

._timeline-wrapper_1qslx_92 {
  width: 170px;
}