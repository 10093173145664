:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._timeline-container_1qbzi_7 {
  width: 100%;
}
._timeline-container_1qbzi_7 ._main-line_1qbzi_10 {
  display: flex;
  overflow: hidden;
  border-radius: 8px;
}
._timeline-container_1qbzi_7 ._main-line_1qbzi_10 ._base-time_1qbzi_15 {
  flex: 1;
  background: #e1e3ed;
  height: 8px;
}
._timeline-container_1qbzi_7 ._main-line_1qbzi_10 ._failure-time_1qbzi_20 {
  background: #e45770;
  height: 8px;
}