.contentLoader {
  animation: shimmer 4s linear infinite;
  background-size: 200% 200%;
}

@keyframes shimmer {
  from {
    background-position: 200% 0;
  }
  to {
    background-position: -200% 0;
  }
}
