:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._container_q19qv_7 {
  display: flex;
  align-items: center;
  width: 100%;
}
._container_q19qv_7 ._arrow_q19qv_12 {
  margin-bottom: -2px;
  margin-right: -4px;
}
._container_q19qv_7 ._line_q19qv_16 {
  height: 3px;
  background: #a06ce4;
  border-top: 1px solid #1b1e2e;
  border-bottom: 1px solid #1b1e2e;
  width: 100%;
}