.reporter {
  .instruments-container {
    .instrument-content {
      background-color: $reporter-section-background;
      border-left: 1px solid $reporter-section-background;
      border-radius: 0 3px 3px;
      border-right: 1px solid $reporter-section-background;
      margin-bottom: 10px;
      overflow: auto;
      padding: 0 2px 0 12px;
    }

    .instrument-content h3,
    h2,
    h1:first-child {
      margin-top: 0;
    }

    table {
      margin-bottom: 0;

      td {
        font-family: $monospace;
        font-size: 11px;
      }
    }

    .call-count {
      white-space: nowrap;
    }

    .no-calls .call-count,
    .no-responses .route-num-responses {
      color: $fail;
    }
  }
}
