:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._test-replay-container_1f264_7 {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
._test-replay-container_1f264_7 * ::selection {
  background-color: rgba(208, 210, 224, 0.3);
}

._test-replay--loading_1f264_17 {
  height: 100%;
  background-color: #2e3247;
  display: flex;
  justify-content: center;
  align-items: center;
}
._test-replay--loading_1f264_17 svg {
  height: 56px;
  width: 56px;
}
._test-replay--loading_1f264_17 svg path {
  fill: #9095ad !important;
}
._test-replay--loading_1f264_17 svg circle {
  fill: #d0d2e0 !important;
}