:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._replay-browser-container_lrc4h_7 {
  height: 100%;
  background-color: #1b1e2e;
}

._replay-dom-wrapper_lrc4h_12 {
  position: relative;
  display: flex;
  justify-content: center;
  background: #2e3247;
  height: calc(100% - 64px);
  padding: 16px;
}
._replay-dom-wrapper_lrc4h_12 iframe {
  border: none;
  background-color: ghostwhite;
}
._replay-dom-wrapper_lrc4h_12 ._aut-control-wrapper_lrc4h_24 {
  position: absolute;
  bottom: 5%;
}