@import '@frontend/design-system/src/styles/_variables';

.reporter {
  // sets the Cypress Reporter in the
  // correct location in the frame:
  position: unset;

  .command-wrapper {
    &:hover {
      background-color: $gray-900;
      cursor: pointer;
    }
  }

  .clear-sessions {
    display: none;
  }

  .runnable-err-print {
    display: none;
  }

  .runnable-err-content {
    overflow: hidden;
  }

  // hide anchor styles until we do something with them
  a {
    cursor: default;

    &:hover {
      color: #6470f3;
      outline: none;
      text-decoration: none;
    }

    &:focus {
      color: #6470f3;
      outline: none;
      text-decoration: none;
    }
  }
}

// Hides button that does not apply
// to the Cloud experience:
.hook-open-in-ide {
  display: none !important;
}
