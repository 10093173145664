body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,legend,input,button,textarea,p,blockquote,th,td{margin:0;padding:0;}table{border-collapse:collapse;border-spacing:0;}fieldset,img,a img{border:none;}address,caption,cite,code,dfn,em,strong,th,var,optgroup{font-style:inherit;font-weight:inherit;}del,ins{text-decoration:none;}li{list-style:none;}caption,th{text-align:left;}h1,h2,h3,h4,h5,h6{font-size:100%;font-weight:normal;}q:before,q:after{content:'';}abbr,acronym{border:0;font-variant:normal;}sup{vertical-align:baseline;}sub{vertical-align:baseline;}legend{color:$white;}

.reporter {
  background-color: $gray-1000;
  bottom: 0;
  color: $gray-200;
  display: flex;
  flex-direction: column;
  font-family: $font-system;
  font-size: 12px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;

  * {
    box-sizing: border-box;
  }

  &,
  input,
  textarea {
    font-family: $font-sans;
  }

  p {
    line-height: 1.8;
    margin-bottom: 10px;
  }

  a {
    color: $indigo-400;
    text-decoration: none;
    outline: none;

    &:hover,
    &:active,
    &:focus {
      color: $indigo-300;
      text-decoration: underline;
      outline: inherit;
    }
  }

  strong {
    color: $gray-50;
    font-weight: bold;
  }

  svg {
    display: inline-block;
    vertical-align: initial;
  }

  em {
    font-style: italic;
  }

  button {
    background: $black;
    border: none;
    border-radius: 2px;
    color: $gray-200;
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.4;
    padding: 10px 16px;
    text-align: center;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    user-select: none;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 300;
    line-height: 1.1;
  }

  pre {
    background-color: $gray-1000;
    border: 1px solid $gray-800;
    border-radius: 2px;
    color: $gray-100;
    display: block;
    font-family: $open-sans;
    font-size: 1.05em;
    letter-spacing: 0.5px;
    margin: 0 0 10px;
    overflow: auto;
    padding: 9.5px;
    line-height: 1.4;
    word-break: break-all;
    word-wrap: break-word;
  }

  table {
    background-color: transparent;
    border-collapse: collapse;
    border-spacing: 0;
    max-width: 100%;
    width: 100%;

    > thead > tr > th,
    > thead > tr > td,
    > tbody > tr > th,
    > tbody > tr > td,
    > tfoot > tr > th,
    > tfoot > tr > td {
      border-top: 1px solid rgba($gray-900, 0.4);
      line-height: 1.42857;
      padding: 5px;
      vertical-align: top;
    }

    > thead > tr > th {
      vertical-align: bottom;
      border-bottom: 2px solid rgba($gray-900, 0.4);
    }

    > caption + thead > tr:first-child > th,
    > caption + thead > tr:first-child > td,
    > colgroup + thead > tr:first-child > th,
    > colgroup + thead > tr:first-child > td,
    > thead:first-child > tr:first-child > th,
    > thead:first-child > tr:first-child > td {
      border-top: 0;
    }
  }

  th {
    text-align: left;
  }

  label {
    background-color: $gray-100;
    border-radius: .25em;
    color: $black;
    display: inline;
    font-size: 75%;
    font-weight: bold;
    line-height: 1;
    padding: .2em .6em .3em;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
  }

  .hidden {
    display: none;
  }
}

/* Used to provide additional context for screen readers */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
