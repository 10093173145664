:root {
  --icon-light: #d0d2e0;
  --icon-dark: #1b1e2e;
  --icon-white: #ffffff;
}

._tableContainer_1ceo9_7 {
  margin-bottom: 2px;
  overflow-x: scroll;
}
._tableContainer_1ceo9_7 table {
  width: 100%;
  border: 1px #434861 solid;
  background-color: #2e3247;
}
._tableContainer_1ceo9_7 th {
  padding: 2px 4px;
  font-size: 12px;
  border: 1px #434861 solid;
  color: #9095ad;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  min-width: 30px;
}
._tableContainer_1ceo9_7 td {
  color: #bfc2d4;
  padding: 2px 4px;
  font-size: 12px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  min-width: 30px;
}
._tableContainer_1ceo9_7 tbody td {
  border-right: 1px #434861 solid;
  border-bottom: 1px #434861 solid;
}

._leadingContainer_1ceo9_42 {
  width: 64px;
  display: flex;
  height: 16px;
  align-items: center;
  gap: 8px;
}
._leadingContainer_1ceo9_42 ._waterfallPillContainer_1ceo9_49 {
  margin-bottom: -2px;
  height: 16px;
  width: 40px;
}
._leadingContainer_1ceo9_42 ._icon_1ceo9_54 {
  margin-top: -4px;
  height: 12px;
  width: 12px;
}
._leadingContainer_1ceo9_42 ._icon-info_1ceo9_59 {
  transform: rotate(180deg);
  margin-bottom: -8px;
  margin-top: 0px;
}
._leadingContainer_1ceo9_42 ._icon-ghost_1ceo9_64 svg path {
  fill: #9095ad;
}

._message_1ceo9_68 {
  gap: 8px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  overflow-wrap: break-word;
  font-size: 12px;
}
._message_1ceo9_68 ._italicMessage_1ceo9_76 {
  font-style: italic;
}
._message_1ceo9_68 ._ghost_1ceo9_79 {
  color: #9095ad;
}

._logCount_1ceo9_83 {
  height: 16px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 2px 4px;
  margin-top: 3px;
  font-size: 12px;
  font-weight: 500;
  min-width: 25px;
  width: 25px;
  background-color: #9095ad;
}
._logCount-warning_1ceo9_98 {
  background-color: #edbb4a;
  color: #521f00;
}
._logCount-error_1ceo9_102 {
  background-color: #c62b49;
}
._logCount-ghost_1ceo9_105 {
  background-color: #434861;
  color: #afb3c7;
}