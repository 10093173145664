// dark-mode styles for all scroll bars
// to approximate Cypress TestRunner ux:
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
::-webkit-scrollbar-thumb {
  border-radius: 1ex;
  background-clip: padding-box;
  border: 3px solid transparent;
  // approximates TestRunner styles,
  // not available in design system:
  background-color: #afb3c7;
}
::-webkit-scrollbar-track {
  // approximates TestRunner styles,
  // not available in design system:
  background-color: transparent;
}
