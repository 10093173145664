// Meant to prevent flex-containers from breaking ellipsis
// and flex-children from overflowing their parents:
* {
  min-width: 0;
  min-height: 0;
}

// shields reporter
// from our global rules:
.reporter,
.reporter * {
  min-width: auto;
  min-height: auto;
}

@media only percy {
  .hide-in-percy {
    visibility: hidden;
  }
}

// order of imports can matter:
@import './overrides-prism.scss';
@import './overrides-tooltip.scss';
@import './overrides-reporter.scss';
@import './overrides-scrollbar.scss';
@import './overrides-ghost-logs.scss';
